import { put, takeEvery, retry, select, call, all, debounce, delay, takeLatest } from 'redux-saga/effects';
import {
  GET_MARGIN_REQUEST,
  GET_AP_GROUPS_REQUEST,
  GET_AP_GROUP_BY_ID_REQUEST,
  GET_AP_GROUP_BY_ID_MULTI_REQUEST,
  UPDATE_AP_GROUP_REQUEST,
  DELETE_AP_GROUP_REQUEST,
  CHANGE_AP_GROUP_ITEM_REQUEST,
  GET_EXECUTIONS_INFO_REQUEST,
  GET_POSITIONS_INFO_REQUEST,
  GET_PL_EXECUTIONS_INFO_REQUEST,
  DELETE_AP_GROUP_ITEM_REQUEST,
  DELETE_ALL_MANUAL_TRADE_POSITIONS_REQUEST,
  GET_MARGIN_PERIODICALLY,
  GET_MARGIN_REQUEST_IMMEDIATELY,
  RESET_SELECTED_EXECUTIONS_META_INFO_DATE,
  RESET_SELECTED_EXECUTIONS_META_INFO_PARTIAL,
  GET_PORTFOLIO_DATA_REQUEST,
} from '../actionConstants/portfolioConstants';
import { GET_RECOMMEND_REQUEST, UPDATE_LAST_RECOMMEND_VIEW_TIME } from '../actionConstants/recommendConstants';
import {
  getMarginSuccess,
  getMarginRequestStartLoading,
  getMarginRequestEndLoading,
  getApGroupRequest,
  getApGroupSuccess,
  getApGroupRequestStartLoading,
  getApGroupRequestEndLoading,
  getApGroupByIdSuccess,
  getApGroupByIdRequest,
  getApGroupByIdRequestStartLoading,
  getApGroupByIdRequestEndLoading,
  getExecutionsInfoRequestStartLoading,
  getExecutionsInfoSuccess,
  getExecutionsInfoRequestEndLoading,
  getPositionsInfoRequestEndLoading,
  getPositionsInfoRequestStartLoading,
  getPositionsInfoSuccess,
  updateApGroupRequestStartLoading,
  updateApGroupRequestEndLoading,
  deleteApGroupRequestStartLoading,
  deleteApGroupRequestEndLoading,
  getPlExecutionsInfoRequestStartLoading,
  getPlExecutionsInfoRequestEndLoading,
  getPlExecutionsInfoSuccess,
  clearSelectedApGroupData,
  changeApGroupItemStatus,
  changeApGroupItemRequestStartLoading,
  changeApGroupItemRequestEndLoading,
  deleteApGroupItemRequestStartLoading,
  deleteApGroupItemRequestEndLoading,
  removeApGroup,
  getMarginRequestImmediately,
  changeSelectedApGroupStatus,
  clearApGroupByService,
  resetMarginData,
  getApGroupByIdSuccessDoNotUpdateSelectedData,
  changeSelectedExecutionsMetaInfo,
  getPortfolioDataRequestEndLoading,
  getPortfolioDataRequestStartLoading,
} from '../actions/portfolioActions';
import {
  getRecommendDataSuccess,
  getRecommendDataRequestStartLoading,
  getRecommendDataRequestEndLoading,
} from '../actions/recommendActions';
import { updateLastRecommendViewTime, getRecommendData } from '../../api/recommendApi';
import {
  getMargin,
  getApGroups,
  getApGroupsById,
  updateGroup,
  deleteApGroup,
  changeGroupItem,
  deleteGroupItem,
} from '../../api/portfolioApi';
import {
  RETRY_MAX_TRIES,
  RETRY_DELAY,
  DEBOUNCE_DELAY,
  NOT_FOUND_CODE,
  DEBOUNCE_DELAY_ONE_SECOND,
  DELAY_FOR_UPDATE_MARGIN,
} from '../../constants/apiConstant';
import { ALL_SERVICES } from '../../constants/core';
import { MAPPING_TABLE_COLUMN_NAMES_MAIN, SORT_ASCENDING } from '../../constants/manualTrade';
import { getExecutions, getPositions, closeMultiplePositions } from '../../api/manualTradeApi';
import { sendNotificationSuccess } from '../actions/notificationActions';
import { errorHandler } from './errorSaga';
import { checkIsWebApp, date30DaysBefore, isMoreThanOneYearAgo } from '../../services';
import Logger from '../../services/Logger';
import { getAccountInfo } from './common';
import { ACCOUNT_TYPE_MAP, AP_GROUP_ORDER, AP_GROUP_STATUSES, CARD_STATUS_IDS, FX, MIXED } from '../../constants';
import { getPositionsRequestHandler } from './currenciesSaga';
import { getTechnicalBuilderDataHandler } from './techSaga';
import { getTechnicalBuilderDataRequest } from '../tech';
import { doneRequestSequentialModal, getPositionsRequest } from '../actions';
import { arraysEqual, includeComprehensiveEvaluation } from '../../utils';
import { SEQUENTIAL_MODALS } from '../../constants/sequentialModals';

export function* getMarginRequestHandler(action) {
  try {
    let serviceId = action?.payload?.serviceId;
    if (!serviceId) {
      serviceId = yield select((state) => state.auth.serviceId);
    }
    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      put(resetMarginData({ serviceId }));
      return;
    }

    yield put(getMarginRequestStartLoading());
    const { data: marginData } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getMargin, serviceId);

    yield put(getMarginSuccess({ marginData, serviceId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getMarginRequestEndLoading());
  }
}

function* getApGroupsHandler({ payload: { groupId, serviceId: specifiedServiceId, status } }) {
  const tutorialMode = yield select((state) => state.tutorial.tutorialMode);
  if (tutorialMode) {
    return;
  }

  try {
    const currentServiceId = yield select((state) => state.auth.serviceId);
    const serviceId = specifiedServiceId ?? currentServiceId;
    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      yield put(clearApGroupByService({ serviceId }));
      return;
    }

    if (!groupId) yield put(getApGroupRequestStartLoading());
    const { data: apGroupsData } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getApGroups, {
      serviceId,
      groupId,
      status,
    });

    yield put(getApGroupSuccess({ serviceId, apGroupsData, groupId }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getApGroupRequestEndLoading());
  }
}

function* getApGroupsByIdHandler(action) {
  try {
    const currentServiceId = yield select((state) => state.auth.serviceId);
    const {
      payload: { id, doNotReload, serviceId = currentServiceId, doNotUpdateSelectedData },
    } = action;

    yield put(getApGroupByIdRequestStartLoading());
    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      yield put(clearApGroupByService({ serviceId }));
      yield put(clearSelectedApGroupData());
      return;
    }

    if (!doNotReload) {
      yield put(clearSelectedApGroupData());
    }

    const { data: apGroupData } = yield call(getApGroupsById, { id, serviceId });
    if (!doNotUpdateSelectedData) {
      yield put(getApGroupByIdSuccess({ data: apGroupData, serviceId }));
    } else {
      yield put(getApGroupByIdSuccessDoNotUpdateSelectedData({ data: apGroupData, serviceId }));
    }
  } catch (e) {
    if (e?.response?.status === NOT_FOUND_CODE) {
      return;
    }
    Logger.error(e);
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getApGroupByIdRequestEndLoading());
    yield put(changeApGroupItemRequestEndLoading());
  }
}

function* getApGroupsByIdMultiHandler(action) {
  try {
    yield put(getApGroupByIdRequestStartLoading());
    const {
      payload: { apGroupIds, serviceId, callback },
    } = action;
    const accountInfo = yield* getAccountInfo();
    if (accountInfo[serviceId].isNotAvailable) {
      return;
    }

    const results = yield all(apGroupIds.map((id) => call(getApGroupsById, { id, serviceId })));

    const data = results.reduce((acc, cur) => acc.concat(cur.data), []);
    callback?.(data);
  } catch (e) {
    if (e?.response?.status === NOT_FOUND_CODE) {
      return;
    }
    Logger.error(e);
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getApGroupByIdRequestEndLoading());
  }
}
function* updateApGroupHandler(action) {
  try {
    yield put(updateApGroupRequestStartLoading());
    const {
      payload: { groupId, apGroupName, status, serviceId },
    } = action;
    yield call(updateGroup, {
      groupId,
      serviceId,
      requestBody: { apGroupName, status },
    });
    yield put(changeSelectedApGroupStatus({ status: String(status) }));
    yield put(getApGroupByIdRequest({ id: groupId, serviceId, status }));
    yield put(getApGroupRequest({ serviceId }));

    yield put(sendNotificationSuccess({ message: '設定変更が完了しました' }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(updateApGroupRequestEndLoading());
  }
}

function* deleteApGroupHandler(action) {
  try {
    yield put(deleteApGroupRequestStartLoading());

    const {
      payload: { groupId, callback, successMessage, notificationDelay, serviceId },
    } = action;
    yield call(deleteApGroup, { groupId, serviceId });
    yield put(removeApGroup({ serviceId, groupId }));

    callback?.();
    if (notificationDelay) yield delay(notificationDelay);

    yield put(sendNotificationSuccess({ message: successMessage }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(deleteApGroupRequestEndLoading());
  }
}

function* changeApGroupItemRequestHandler(action) {
  const {
    payload: { groupId, apId, data, callback, serviceId, status, nextStatus },
  } = action;
  try {
    yield put(changeApGroupItemRequestStartLoading());

    if (Number.isFinite(data.status)) {
      yield put(changeApGroupItemStatus({ groupId, apId, status: data.status }));
    }
    yield call(changeGroupItem, { groupId, serviceId, apId, requestBody: data, status });

    if (callback) {
      callback();
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    // loading turns off in the end of getApGroupsByIdHandler saga
    yield put(getApGroupByIdRequest({ id: groupId, serviceId, doNotReload: true, status: nextStatus }));
  }
}

function* deleteApGroupItemRequestHandler(action) {
  const {
    payload: { groupId, apId, callback, successMessage, notificationDelay, status, serviceId },
  } = action;
  try {
    yield put(deleteApGroupItemRequestStartLoading());

    yield call(deleteGroupItem, { groupId, serviceId, apId });

    callback?.();

    if (notificationDelay) yield delay(notificationDelay);

    yield put(sendNotificationSuccess({ message: successMessage }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getApGroupByIdRequest({ id: groupId, status, serviceId }));
    yield put(deleteApGroupItemRequestEndLoading());
  }
}

const EMPTY_SUMMARY = {
  summarizedPl: null,
  summarizedSwap: null,
  summarizedDividend: null,
  summarizedComm: null,
  summarizedBuyQuantity: null,
  summarizedSellQuantity: null,
};

function* getExecutionsDataByParamsHandler(action) {
  const { apGroupId, isFirstData, serviceId } = action?.payload;
  try {
    yield put(getExecutionsInfoRequestStartLoading({ serviceId }));

    let tableMetaInfo = yield select((state) => state.portfolio.selectedApGroupExecutionsMetaInfo[serviceId]);

    if (!checkIsWebApp()) {
      if (isFirstData) {
        if (tableMetaInfo.fromDate == null || tableMetaInfo.toDate == null) {
          const fromDate = date30DaysBefore();
          const toDate = new Date();
          yield put(changeSelectedExecutionsMetaInfo({ key: 'fromDate', value: fromDate, serviceId }));
          yield put(changeSelectedExecutionsMetaInfo({ key: 'toDate', value: toDate, serviceId }));
          yield put(changeSelectedExecutionsMetaInfo({ key: 'searchedFromDate', value: fromDate, serviceId }));
          yield put(changeSelectedExecutionsMetaInfo({ key: 'searchedToDate', value: toDate, serviceId }));
          tableMetaInfo = yield select((state) => state.portfolio.selectedApGroupExecutionsMetaInfo[serviceId]);
        } else {
          yield put(
            changeSelectedExecutionsMetaInfo({ key: 'searchedFromDate', value: tableMetaInfo.fromDate, serviceId }),
          );
          yield put(
            changeSelectedExecutionsMetaInfo({ key: 'searchedToDate', value: tableMetaInfo.toDate, serviceId }),
          );
        }
      } else if (tableMetaInfo.fromDate == null || tableMetaInfo.toDate == null) {
        // リセット条件にバックグラウンド移行時が追加された場合の措置
        // 上記の場合、resetSelectedExecutionsMetaInfoDateHandler でリセットが行われ、searchedXXX には値が残っていることを期待する
        yield put(
          changeSelectedExecutionsMetaInfo({ key: 'fromDate', value: tableMetaInfo.searchedFromDate, serviceId }),
        );
        yield put(changeSelectedExecutionsMetaInfo({ key: 'toDate', value: tableMetaInfo.searchedToDate, serviceId }));
        tableMetaInfo = yield select((state) => state.portfolio.selectedApGroupExecutionsMetaInfo[serviceId]);
      } else {
        yield put(
          changeSelectedExecutionsMetaInfo({ key: 'searchedFromDate', value: tableMetaInfo.fromDate, serviceId }),
        );
        yield put(changeSelectedExecutionsMetaInfo({ key: 'searchedToDate', value: tableMetaInfo.toDate, serviceId }));
      }
    }

    // don't search if time is more then 1 year ago
    if (isMoreThanOneYearAgo(tableMetaInfo.fromDate)) {
      yield put(
        getExecutionsInfoSuccess({
          data: [],
          totalPages: 1,
          pageNumber: 1,
          isFirstData: true,
          serviceId,
          summary: EMPTY_SUMMARY,
        }),
      );
      return;
    }

    if (isFirstData) {
      yield put(
        getExecutionsInfoSuccess({
          data: [],
          totalPages: 1,
          pageNumber: 1,
          isFirstData,
          serviceId,
          summary: EMPTY_SUMMARY,
        }),
      );
    }

    const newPageNumber = isFirstData ? 1 : tableMetaInfo.pageNumber + 1;
    if ((!isFirstData && newPageNumber > tableMetaInfo.totalPages) || !apGroupId) {
      return;
    }
    const {
      data: {
        list: data,
        pageInfo: { totalPages, pageNumber },
        summary = EMPTY_SUMMARY,
      },
    } = yield call(getExecutions, {
      pageNumber: newPageNumber,
      apGroupId,
      fromDate: tableMetaInfo.fromDate,
      toDate: tableMetaInfo.toDate,
      sortBy: tableMetaInfo.sortBy,
      sortDir: tableMetaInfo.sortDir,
      serviceId,
      isClose: tableMetaInfo.isClose,
      side: tableMetaInfo.side,
    });

    yield put(getExecutionsInfoSuccess({ data, totalPages, pageNumber, isFirstData, serviceId, summary }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getExecutionsInfoRequestEndLoading({ serviceId }));
  }
}

function* getPlExecutionsInfoRequestHandler(action) {
  try {
    yield put(getPlExecutionsInfoRequestStartLoading());

    const {
      payload: { apGroupId, fromDate, toDate, serviceId },
    } = action;

    const currentServiceId = yield select((state) => state.auth.serviceId);

    let result;
    const requestOptions = {
      apGroupId,
      fromDate,
      toDate,
      withoutPagination: true,
      sortBy: MAPPING_TABLE_COLUMN_NAMES_MAIN.EXEC_TIME.ID,
      sortDir: SORT_ASCENDING,
      serviceId: serviceId ?? currentServiceId,
    };

    const {
      data: {
        list,
        pageInfo: { totalPages },
      },
    } = yield call(getExecutions, { pageNumber: 1, ...requestOptions });
    result = [...list];

    if (totalPages > 1) {
      const promiseArray = [];
      for (let i = 2; i <= totalPages; i += 1) {
        promiseArray.push(() => call(getExecutions, { pageNumber: i, ...requestOptions }));
      }
      const promiseResult = yield all(promiseArray.map((item) => item()));
      promiseResult.forEach((partialResult) => {
        const {
          data: { list: partialList },
        } = partialResult;
        result = [...result, ...partialList];
      });
    }
    yield put(getPlExecutionsInfoSuccess(result));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getPlExecutionsInfoRequestEndLoading());
  }
}

function* getOpenPositionsDataByParamsHandler(action) {
  try {
    yield put(getPositionsInfoRequestStartLoading());
    let result;
    const {
      payload: { instrumentId, side, apGroupId, serviceId },
    } = action;
    const {
      data: {
        list,
        pageInfo: { totalPages },
      },
    } = yield call(getPositions, { instrumentId, side, apGroupId, serviceId, pageNumber: 1 });
    result = [...list];

    if (totalPages > 1) {
      const promiseArray = [];
      for (let i = 2; i <= totalPages; i += 1) {
        promiseArray.push(() => call(getPositions, { instrumentId, side, apGroupId, serviceId, pageNumber: i }));
      }
      const promiseResult = yield all(promiseArray.map((item) => item()));
      promiseResult.forEach((partialResult) => {
        const {
          data: { list: partialList },
        } = partialResult;
        result = [...result, ...partialList];
      });
    }

    yield put(getPositionsInfoSuccess(result));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(getPositionsInfoRequestEndLoading());
  }
}

function* deleteAllManualTradePositionsHandler(action) {
  try {
    const currentServiceId = yield select((state) => state.auth.serviceId);
    yield put(deleteApGroupRequestStartLoading());
    const {
      payload: { instrumentIdAndSide, callback, successMessage, notificationDelay, serviceId },
    } = action;
    yield call(closeMultiplePositions, { serviceId: serviceId ?? currentServiceId, requestBody: instrumentIdAndSide });

    if (callback) callback();
    if (notificationDelay) yield delay(notificationDelay);

    yield put(sendNotificationSuccess({ message: successMessage }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(deleteApGroupRequestEndLoading());
  }
}

export function* getMarginPeriodicallyHandler() {
  try {
    while (true) {
      const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
      const hasError = yield select((state) => state.socket.hasError);
      if (!isAuthenticated || hasError) {
        break;
      }
      const accountInfo = yield* getAccountInfo();
      yield all(
        ALL_SERVICES.filter((serviceId) => !accountInfo[serviceId].isNotAvailable).map((serviceId) =>
          put(getMarginRequestImmediately({ serviceId })),
        ),
      );
      yield delay(DELAY_FOR_UPDATE_MARGIN);
    }
  } catch (e) {
    Logger.error(e);
  }
}

function* resetSelectedExecutionsMetaInfoDateHandler() {
  try {
    yield all(
      ALL_SERVICES.flatMap((serviceId) => [
        put(changeSelectedExecutionsMetaInfo({ key: 'fromDate', value: null, serviceId })),
        put(changeSelectedExecutionsMetaInfo({ key: 'toDate', value: null, serviceId })),
      ]),
    );
  } catch (e) {
    Logger.error(e);
  }
}

function* resetSelectedExecutionsMetaInfoPartialHandler() {
  try {
    yield all(
      ALL_SERVICES.flatMap((serviceId) => [
        put(changeSelectedExecutionsMetaInfo({ key: 'fromDate', value: null, serviceId })),
        put(changeSelectedExecutionsMetaInfo({ key: 'toDate', value: null, serviceId })),
        put(changeSelectedExecutionsMetaInfo({ key: 'searchedFromDate', value: null, serviceId })),
        put(changeSelectedExecutionsMetaInfo({ key: 'searchedToDate', value: null, serviceId })),
      ]),
    );
  } catch (e) {
    Logger.error(e);
  }
}

function* getRecommendRequestHandler() {
  let gotRecommend = false;
  try {
    yield put(getRecommendDataRequestStartLoading());
    const accountInfo = yield select((state) => state.settings.accountInfo);
    const assetInfo = yield* getAccountInfo();
    const isAnyAssetMaintenance = ALL_SERVICES.some((service) => assetInfo[service].isMaintenance);
    if (
      isAnyAssetMaintenance ||
      !accountInfo[FX]?.firstRecord?.firstDeposit ||
      !accountInfo[FX]?.firstRecord?.firstOrder
    ) {
      return;
    }
    // TODO matsuzaki100983 レコメンドの対象をFXに固定しているので全アセット対象とする場合は修正
    const { date: firstDepositDate, amount: firstDepositAmount } = accountInfo[FX].firstRecord.firstDeposit;
    if (firstDepositDate === '') {
      return;
    }
    // TODO matsuzaki100983 レコメンドの対象をFXに固定しているので全アセット対象とする場合は修正
    const hasMadeOrders = accountInfo[FX].firstRecord.firstOrder.date !== '';
    const userId = yield select((state) => state.auth.portalId);

    const accountArray = ALL_SERVICES.filter((serviceId) => accountInfo[serviceId]);
    const accountType = Object.keys(ACCOUNT_TYPE_MAP).find((key) => arraysEqual(ACCOUNT_TYPE_MAP[key], accountArray));

    const { data } = yield call(getRecommendData, {
      userId,
      accountType,
      hasMadeOrders,
      firstDepositDate,
      firstDepositAmount,
    });
    let recommendData = null;
    const { recommendRulesNewType, recommendRulesAddType } = data;
    if (!recommendRulesNewType && !recommendRulesAddType) {
      return;
    }
    if (recommendRulesNewType) {
      [recommendData] = recommendRulesNewType.recommendItems;
      if (!recommendData) {
        return;
      }
    }
    if (recommendRulesAddType) {
      // TODO matsuzaki100983 レコメンドの対象をFXに固定しているので全アセット対象とする場合は修正
      const fxApGroups = yield select((state) => state.portfolio.apGroupsData[FX]);
      const activeTargetInstruments = new Set(
        fxApGroups
          .filter((group) => {
            return (
              group.status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID &&
              recommendRulesAddType.recommendTargetInstrumentList.includes(group.instrumentId)
            );
          })
          .map((group) => group.instrumentId),
      );
      recommendData = recommendRulesAddType.recommendItems.find(
        (item) => item.recommendCondition === activeTargetInstruments.size,
      );
      if (!recommendData) {
        return;
      }
      const recommendRules = recommendData?.recommendTargetInstrument?.find((target) =>
        arraysEqual(target.targetInstrumentIds, Array.from(activeTargetInstruments)),
      )?.recommendRules;

      if (!recommendRules) {
        return;
      }
      recommendData.recommendRules = recommendRules;
    }

    const instrumentList = yield select((state) => state.settings.instrumentList);

    const transformSelectionData = (row, instruments) => {
      let serviceIds;
      const { serviceId, simulationStats } = row;
      if (serviceId === MIXED) {
        serviceIds = Array.from(
          new Set(
            simulationStats.selectionStrategyDetailList.map((detail) => instruments[detail.instrumentId].serviceId),
          ),
        );
      } else {
        serviceIds = [serviceId];
      }
      return { ...row, serviceIds };
    };
    let transformedRecommendRules = [];

    if (recommendData.recommendRules) {
      transformedRecommendRules = recommendData.recommendRules.map((rule) => {
        return {
          ...rule,
          selectionInfo: includeComprehensiveEvaluation(transformSelectionData(rule.selectionInfo, instrumentList)),
        };
      });
    }
    transformedRecommendRules = transformedRecommendRules.sort((a, b) => {
      const aComprehensiveEvaluation = a?.selectionInfo?.attribute?.comprehensiveEvaluation || 0;
      const bComprehensiveEvaluation = b?.selectionInfo?.attribute?.comprehensiveEvaluation || 0;
      if (aComprehensiveEvaluation !== bComprehensiveEvaluation) {
        return bComprehensiveEvaluation - aComprehensiveEvaluation;
      }
      const aRoi = a?.selectionInfo?.simulationStats?.roi || 0;
      const bRoi = b?.selectionInfo?.simulationStats?.roi || 0;
      return bRoi - aRoi;
    });
    recommendData.recommendRules = transformedRecommendRules.slice(0, 2);

    yield put(getRecommendDataSuccess({ recommendData }));
    gotRecommend = true;
  } catch (e) {
    Logger.error(e);
  } finally {
    if (!gotRecommend) {
      yield put(doneRequestSequentialModal({ modalId: SEQUENTIAL_MODALS.Recommend }));
    }
    yield put(getRecommendDataRequestEndLoading());
  }
}

function* getPortfolioDataRequestHandler() {
  try {
    yield put(getPortfolioDataRequestStartLoading());
    const getTechnicalBuilderData = call(
      getTechnicalBuilderDataHandler,
      getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[AP_GROUP_STATUSES.ALL_STATUSES] }),
    );
    yield all(
      ALL_SERVICES.flatMap((serviceId) => [
        call(getApGroupsHandler, getApGroupRequest({ serviceId })),
        call(getPositionsRequestHandler, getPositionsRequest({ serviceId })),
      ]).concat([getTechnicalBuilderData]),
    );
  } catch (e) {
    Logger.error(e);
  } finally {
    yield put(getPortfolioDataRequestEndLoading());
    yield* getRecommendRequestHandler();
  }
}

function* updateLastRecommendViewTimeHandler() {
  try {
    const userId = yield select((state) => state.auth.portalId);
    yield call(updateLastRecommendViewTime, {
      userId,
    });
  } catch (e) {
    Logger.error(e);
  }
}

export default function* portfolioSagaHandler() {
  yield debounce(DEBOUNCE_DELAY_ONE_SECOND, GET_MARGIN_REQUEST, getMarginRequestHandler);
  yield takeEvery(GET_AP_GROUPS_REQUEST, getApGroupsHandler);
  yield debounce(DEBOUNCE_DELAY, GET_AP_GROUP_BY_ID_REQUEST, getApGroupsByIdHandler);
  yield debounce(DEBOUNCE_DELAY, GET_AP_GROUP_BY_ID_MULTI_REQUEST, getApGroupsByIdMultiHandler);
  yield takeEvery(UPDATE_AP_GROUP_REQUEST, updateApGroupHandler);
  yield takeEvery(DELETE_AP_GROUP_REQUEST, deleteApGroupHandler);
  yield debounce(DEBOUNCE_DELAY, CHANGE_AP_GROUP_ITEM_REQUEST, changeApGroupItemRequestHandler);
  yield debounce(DEBOUNCE_DELAY, DELETE_AP_GROUP_ITEM_REQUEST, deleteApGroupItemRequestHandler);
  yield debounce(DEBOUNCE_DELAY, GET_EXECUTIONS_INFO_REQUEST, getExecutionsDataByParamsHandler);
  yield takeEvery(GET_POSITIONS_INFO_REQUEST, getOpenPositionsDataByParamsHandler);
  yield debounce(DEBOUNCE_DELAY, GET_PL_EXECUTIONS_INFO_REQUEST, getPlExecutionsInfoRequestHandler);
  yield takeEvery(DELETE_ALL_MANUAL_TRADE_POSITIONS_REQUEST, deleteAllManualTradePositionsHandler);
  yield takeEvery(GET_MARGIN_REQUEST_IMMEDIATELY, getMarginRequestHandler);
  yield takeLatest(GET_MARGIN_PERIODICALLY, getMarginPeriodicallyHandler);
  yield takeEvery(RESET_SELECTED_EXECUTIONS_META_INFO_DATE, resetSelectedExecutionsMetaInfoDateHandler);
  yield takeEvery(RESET_SELECTED_EXECUTIONS_META_INFO_PARTIAL, resetSelectedExecutionsMetaInfoPartialHandler);
  yield takeLatest(GET_PORTFOLIO_DATA_REQUEST, getPortfolioDataRequestHandler);
  yield takeEvery(GET_RECOMMEND_REQUEST, getRecommendRequestHandler);
  yield takeLatest(UPDATE_LAST_RECOMMEND_VIEW_TIME, updateLastRecommendViewTimeHandler);
}
