import { TAB_NEWS } from '../../constants/message';
import {
  CHECK_INITIAL_DEPOSIT_SUCCESS,
  CLEAR_MESSAGES,
  CLOSE_MESSAGE_MODAL,
  GET_MESSAGES_REQUEST_START_LOADING,
  GET_MESSAGES_REQUEST_STOP_LOADING,
  GET_MESSAGES_SUCCESS,
  GET_RANGE_OUTS_SUCCESS,
  GET_UNREAD_COUNT_SUCCESS,
  OPEN_MESSAGE_MODAL,
  REFRESH_MESSAGES_SUCCESS,
  RESET_MESSAGE_MODAL,
  SET_CURRENT_TAB,
  SET_DEFAULT_OPENED_MESSAGE_ID,
  START_AUTO_REFRESH_MESSAGE,
  STOP_AUTO_REFRESH_MESSAGE,
} from '../actionConstants/messageConstants';

const emptyMessageModalInfo = Object.freeze({});

export const initialState = () => ({
  unreadCount: 0,
  unreadCountEvent: 0,
  unreadCountIndividual: 0,
  messages: [],
  loading: false,
  autoRefresh: false,
  currentTab: TAB_NEWS,
  initialDepositState: {},
  rangeOutState: [],
  isOpen: false,
  messageModalInfo: emptyMessageModalInfo,
  defaultOpenedMessageId: null,
});

export default (state = initialState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: payload.unreadCountEvent + payload.unreadCountIndividual,
        unreadCountEvent: payload.unreadCountEvent,
        unreadCountIndividual: payload.unreadCountIndividual,
      };
    case GET_MESSAGES_REQUEST_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MESSAGES_REQUEST_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GET_MESSAGES_SUCCESS:
    case REFRESH_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: payload.messages,
      };
    case START_AUTO_REFRESH_MESSAGE:
      return {
        ...state,
        autoRefresh: true,
      };
    case STOP_AUTO_REFRESH_MESSAGE:
      return {
        ...state,
        autoRefresh: false,
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload.currentTab,
      };
    case CHECK_INITIAL_DEPOSIT_SUCCESS:
      return {
        ...state,
        initialDepositState: payload.initialDepositState,
      };
    case GET_RANGE_OUTS_SUCCESS:
      return {
        ...state,
        rangeOutState: payload.rangeOutState,
      };
    case OPEN_MESSAGE_MODAL: {
      const { message } = payload;
      return {
        ...state,
        isOpen: true,
        messageModalInfo: message,
      };
    }
    case CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    case RESET_MESSAGE_MODAL:
      return {
        ...state,
        isOpen: false,
        messageModalInfo: emptyMessageModalInfo,
      };
    case SET_DEFAULT_OPENED_MESSAGE_ID: {
      const { defaultOpenedMessageId } = payload;
      return {
        ...state,
        defaultOpenedMessageId,
      };
    }
    default:
      return state;
  }
};
