import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { CFD } from 'shared-modules/constants';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import useBuilderMultiFormLogic from 'shared-modules/services/hooks/builderMultiFormLogic';
import {
  BackLink,
  Button,
  BuySellGroupButton,
  GroupButton,
  InputNumber,
  Switch,
  HelpHalfModal,
} from '../../../../../components';
import {
  changeBuilderActivePage,
  resetBuilderChartData,
  resetBuilderMultiOrderOptions,
  resetBuilderOrdersTables,
  openBuilderConfigurationFlowModal,
} from '../../../../../redux/actions';
import { TagButton } from './TagButton';
import styles from './sideMenu.module.scss';
import infoImagesHelper from '../../../../../assets/builderInfo/infoImagesHelper';

const shortNumberInputWidth = 80;

export const SideMenu = memo(() => {
  const dispatch = useDispatch();
  const multiFormLogic = useBuilderMultiFormLogic(undefined, true);
  const serviceId = useSelector((state) => state.auth.serviceId);

  const handleClick = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.CURRENCY_TYPE_PAGE.ID }));
    dispatch(resetBuilderMultiOrderOptions());
    dispatch(resetBuilderOrdersTables());
    dispatch(resetBuilderChartData());
  }, [dispatch]);

  const onCheckConfigurationFlow = useCallback(() => dispatch(openBuilderConfigurationFlowModal()), [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.row}>
          <BackLink onClick={handleClick}>選択に戻る</BackLink>
          <TagButton onClick={onCheckConfigurationFlow} disabled={multiFormLogic.reset.isDisabled}>
            設定の流れ
          </TagButton>
        </div>
        <div className={classNames(styles.row, styles.right)}>
          <TagButton onClick={multiFormLogic.reset.handler} disabled={multiFormLogic.reset.isDisabled}>
            初期設定に戻る
          </TagButton>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>新規注文の条件設定</div>
        <div className={styles.settings}>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.buySell.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.buySell.tooltipMessage}
                logicName={multiFormLogic.buySell.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.buySell.descriptionImage]}
              />
            </div>
            <BuySellGroupButton
              buySell={multiFormLogic.buySell.get}
              onChange={multiFormLogic.buySell.set}
              disabledSell={multiFormLogic.buySell.isSellDisabled}
              disabledBuy={multiFormLogic.buySell.isBuyDisabled}
              isNotSelectable={multiFormLogic.buySell.isNotSelectable}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.entryPriceSwitch.label}
              {multiFormLogic.entryPriceInput.condition &&
                multiFormLogic.entryPriceInput.withPips &&
                !!multiFormLogic.entryPriceInput.pipsLabel &&
                `(${multiFormLogic.entryPriceInput.pipsLabel})`}
              {!multiFormLogic.entryPriceInput.condition &&
                !!multiFormLogic.entryPricePipsValue.pipsLabel &&
                `(${multiFormLogic.entryPricePipsValue.pipsLabel})`}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.entryPricePipsValue.tooltipMessage}
                logicName={multiFormLogic.entryPricePipsValue.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.entryPricePipsValue.descriptionImage]}
              />
            </div>
            <GroupButton
              buttonWidth={84}
              buttons={multiFormLogic.entryPriceSwitch.options}
              activeId={multiFormLogic.entryPriceSwitch.get}
              onChange={multiFormLogic.entryPriceSwitch.set}
              disabled={multiFormLogic.entryPriceSwitch.isDisabled}
            />
          </div>
          <div className={classNames(styles.item, styles.right)}>
            {multiFormLogic.entryPriceInput.condition ? (
              <InputNumber
                width={shortNumberInputWidth}
                className={styles.extensionInputWrapper}
                value={multiFormLogic.entryPriceInput.get}
                onChange={multiFormLogic.entryPriceInput.set}
                disabled={multiFormLogic.entryPriceInput.isDisabled}
                step={multiFormLogic.entryPriceInput.step}
                name={multiFormLogic.entryPriceInput.name}
                errorMessages={multiFormLogic.errorMessages}
                withErrorTooltip
              />
            ) : (
              <InputNumber
                width={shortNumberInputWidth}
                className={styles.extensionInputWrapper}
                value={multiFormLogic.entryPricePipsValue.get}
                onChange={multiFormLogic.entryPricePipsValue.set}
                disabled={multiFormLogic.entryPricePipsValue.isDisabled}
                step={multiFormLogic.entryPricePipsValue.step}
                min={multiFormLogic.entryPricePipsValue.min}
                name={multiFormLogic.entryPricePipsValue.name}
                errorMessages={multiFormLogic.errorMessages}
                withErrorTooltip
                validateNegativeValues
              />
            )}
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.ocoCheckbox.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.ocoCheckbox.tooltipMessage}
                logicName={multiFormLogic.ocoCheckbox.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.ocoCheckbox.descriptionImage]}
              />
            </div>
            <Switch
              checked={multiFormLogic.ocoCheckbox.get}
              onChange={multiFormLogic.ocoCheckbox.set}
              disabled={multiFormLogic.ocoCheckbox.isDisabled}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.rangeSpread.label}
              {!multiFormLogic.rangeSpread.condition &&
                !!multiFormLogic.rangeSpread.pipsLabel &&
                `(${multiFormLogic.rangeSpread.pipsLabel})`}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.rangeSpread.tooltipMessage}
                logicName={multiFormLogic.rangeSpread.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.rangeSpread.descriptionImage]}
              />
            </div>
            <InputNumber
              value={multiFormLogic.rangeSpread.get}
              onChange={multiFormLogic.rangeSpread.set}
              disabled={multiFormLogic.rangeSpread.isDisabled}
              step={multiFormLogic.rangeSpread.step}
              name={multiFormLogic.rangeSpread.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.itemsCount.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.itemsCount.tooltipMessage}
                logicName={multiFormLogic.itemsCount.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.itemsCount.descriptionImage]}
              />
            </div>
            <InputNumber
              width={shortNumberInputWidth}
              value={multiFormLogic.itemsCount.get}
              onChange={multiFormLogic.itemsCount.set}
              disabled={multiFormLogic.itemsCount.isDisabled}
              step={multiFormLogic.itemsCount.step}
              name={multiFormLogic.itemsCount.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
              onlyIntegerAllowed
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              <div className={styles.twoLinesLabel}>
                <div>{multiFormLogic.quantity.label}</div>
                {serviceId === CFD && <div className={styles.unit}>{multiFormLogic.quantityUnitConvFactor.label}</div>}
              </div>
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.quantity.tooltipMessage}
                logicName={multiFormLogic.quantity.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.quantity.descriptionImage]}
              />
            </div>
            <InputNumber
              width={shortNumberInputWidth}
              value={multiFormLogic.quantity.get}
              onChange={multiFormLogic.quantity.set}
              disabled={multiFormLogic.quantity.isDisabled}
              step={multiFormLogic.quantity.step}
              name={multiFormLogic.quantity.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
            />
          </div>
        </div>
        <div className={styles.title}>決済注文の条件設定</div>
        <div className={styles.settings}>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.profitMargin.label}
              {!multiFormLogic.profitMargin.condition &&
                !!multiFormLogic.profitMargin.pipsLabel &&
                `(${multiFormLogic.profitMargin.pipsLabel})`}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.profitMargin.tooltipMessage}
                logicName={multiFormLogic.profitMargin.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.profitMargin.descriptionImage]}
              />
            </div>
            <InputNumber
              value={multiFormLogic.profitMargin.get}
              onChange={multiFormLogic.profitMargin.set}
              disabled={multiFormLogic.profitMargin.isDisabled}
              step={multiFormLogic.profitMargin.step}
              name={multiFormLogic.profitMargin.name}
              errorMessages={multiFormLogic.errorMessages}
              withErrorTooltip
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.stopLossCheckbox.label}
              {!multiFormLogic.stopLossCheckbox.condition &&
                !!multiFormLogic.stopLossCheckbox.pipsLabel &&
                `(${multiFormLogic.stopLossCheckbox.pipsLabel})`}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.stopLossCheckbox.tooltipMessage}
                logicName={multiFormLogic.stopLossCheckbox.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.stopLossCheckbox.descriptionImage]}
              />
            </div>
            <Switch
              checked={multiFormLogic.stopLossCheckbox.get}
              onChange={multiFormLogic.stopLossCheckbox.set}
              disabled={multiFormLogic.stopLossCheckbox.isDisabled}
            />
          </div>
          {multiFormLogic.stopLossCheckbox.get && (
            <div className={classNames(styles.item, styles.right)}>
              <InputNumber
                width={shortNumberInputWidth}
                value={multiFormLogic.stopLossInput.get}
                onChange={multiFormLogic.stopLossInput.set}
                disabled={multiFormLogic.stopLossInput.isDisabled}
                step={multiFormLogic.stopLossInput.step}
                min={multiFormLogic.stopLossInput.min}
                name={multiFormLogic.stopLossInput.name}
                errorMessages={multiFormLogic.errorMessages}
                withErrorTooltip
                validateNegativeValues
              />
            </div>
          )}
        </div>
        <div className={styles.title}>再エントリーの条件設定</div>
        <div className={styles.settings}>
          {multiFormLogic.invalidCounter.condition && (
            <div className={classNames(styles.item, styles.validationErrorMessage)}>
              {multiFormLogic.invalidCounter.message}
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.followCheckbox.label}
              {!multiFormLogic.followCheckbox.condition &&
                !!multiFormLogic.followCheckbox.pipsLabel &&
                `(${multiFormLogic.followCheckbox.pipsLabel})`}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.followCheckbox.tooltipMessage}
                logicName={multiFormLogic.followCheckbox.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.followCheckbox.descriptionImage]}
              />
            </div>
            <Switch
              checked={multiFormLogic.followCheckbox.get}
              onChange={multiFormLogic.followCheckbox.set}
              disabled={multiFormLogic.followCheckbox.isDisabled}
            />
          </div>
          {multiFormLogic.followCheckbox.get && (
            <div className={classNames(styles.item, styles.right)}>
              <InputNumber
                width={shortNumberInputWidth}
                value={multiFormLogic.followInput.get}
                onChange={multiFormLogic.followInput.set}
                disabled={multiFormLogic.followInput.isDisabled}
                step={multiFormLogic.followInput.step}
                min={multiFormLogic.followInput.min}
                name={multiFormLogic.followInput.name}
                errorMessages={multiFormLogic.errorMessages}
                validateNegativeValues
                withErrorTooltip
              />
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.counterCheckbox.label}
              {!multiFormLogic.counterCheckbox.condition &&
                !!multiFormLogic.counterCheckbox.pipsLabel &&
                `(${multiFormLogic.counterCheckbox.pipsLabel})`}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.counterCheckbox.tooltipMessage}
                logicName={multiFormLogic.counterCheckbox.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.counterCheckbox.descriptionImage]}
              />
            </div>
            <Switch
              checked={multiFormLogic.counterCheckbox.get}
              onChange={multiFormLogic.counterCheckbox.set}
              disabled={multiFormLogic.counterCheckbox.isDisabled}
            />
          </div>
          {multiFormLogic.counterCheckbox.get && (
            <div className={classNames(styles.item, styles.right)}>
              <InputNumber
                width={shortNumberInputWidth}
                value={multiFormLogic.counterInput.get}
                onChange={multiFormLogic.counterInput.set}
                disabled={multiFormLogic.counterInput.isDisabled}
                step={multiFormLogic.counterInput.step}
                min={multiFormLogic.counterInput.min}
                name={multiFormLogic.counterInput.name}
                errorMessages={multiFormLogic.errorMessages}
                validateNegativeValues
                withErrorTooltip
              />
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.label}>
              {multiFormLogic.counterFixedCheckbox.label}
              <HelpHalfModal
                className={styles.help}
                content={multiFormLogic.counterFixedCheckbox.tooltipMessage}
                logicName={multiFormLogic.counterFixedCheckbox.label}
                logicImageSrc={infoImagesHelper[multiFormLogic.counterFixedCheckbox.descriptionImage]}
              />
            </div>
            <Switch
              checked={multiFormLogic.counterFixedCheckbox.get}
              onChange={multiFormLogic.counterFixedCheckbox.set}
              disabled={multiFormLogic.counterFixedCheckbox.isDisabled}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttonGroup}>
          <Button onClick={multiFormLogic.submit.handler} disabled={multiFormLogic.submit.isDisabled}>
            {multiFormLogic.submit.label}
          </Button>
        </div>
      </div>
    </div>
  );
});
