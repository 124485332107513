import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  LAB_POST_AP_GROUPS_EMPTY_MESSAGE,
  LAB_POST_AP_GROUPS_MAX,
  LAB_POST_AP_GROUPS_MIN,
  LAB_POST_CONDITION,
  LAB_POST_CONDITION_LINK,
  LAB_STEP1_TITLE,
} from 'shared-modules/constants';
import {
  BUILDER_PAGES,
  SHAREABLE_RULE_LOWER_LIMIT_ERROR_MSG,
  SHAREABLE_RULE_UPPER_LIMIT_ERROR_MSG_F,
} from 'shared-modules/constants/builder';
import { getApGroupRequest } from 'shared-modules/redux/actions/portfolioActions';
import {
  formServiceIdSelector,
  labApGroupsSelector,
  resetValidationLab,
  setFormValue,
  validateApGroupsRequest,
  validationSelector,
} from 'shared-modules/redux/labs';
import { useAccountInfo } from 'shared-modules/hooks';
import { changeBuilderActivePage } from '../../../../../redux/actions';
import { BUILDERS_SHARE_PROGRESS_TITLE_STEP0 } from '../../../constants';
import { Step } from '../Step';
import { BuilderItem } from './BuilderItem';
import styles from './builderSelectPage.module.scss';
import { Spin } from '../../../../../components';

export const BuilderSelectPage = memo(({ progress }) => {
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.webBuilder.activePage);
  const activePageRef = useRef(activePage);

  useEffect(() => {
    activePageRef.current = activePage;
  }, [activePage]);

  useEffect(() => {
    dispatch(getApGroupRequest());
  }, [dispatch]);

  const serviceId = useSelector(formServiceIdSelector);
  const { isNotAvailable } = useAccountInfo()[serviceId];
  const isApGroupsLoading = useSelector((state) => state.portfolio.changingApGroupListLoading);
  const original = useSelector(labApGroupsSelector);
  const [apGroups, setApGroups] = useState([]);

  const isCheckMax = useMemo(() => apGroups.filter((a) => a.checked).length > LAB_POST_AP_GROUPS_MAX, [apGroups]);
  const isCheckMin = useMemo(() => apGroups.filter((a) => a.checked).length < LAB_POST_AP_GROUPS_MIN, [apGroups]);

  const checkedApGroups = useMemo(() => apGroups.filter((a) => a.checked), [apGroups]);

  const labForbiddenInstrumentList = useSelector((state) => state.settings[serviceId]?.labForbiddenInstruments) || null;
  const noDisplayInstrumentIds = labForbiddenInstrumentList?.map((item) => item.displayName).join(',');

  const isSelectedApGroupsFinished = useMemo(() => {
    if (original.length === 1) {
      return checkedApGroups.length === 1;
    }
    return checkedApGroups.length >= LAB_POST_AP_GROUPS_MIN && checkedApGroups.length <= LAB_POST_AP_GROUPS_MAX;
  }, [checkedApGroups, original.length]);

  const disabled = isNotAvailable || !isSelectedApGroupsFinished;

  const handleCheck = useCallback(
    (item) => {
      setApGroups(
        apGroups.map((group) => {
          if (group.id !== item.id) {
            return group;
          }
          return { ...group, checked: !item.checked };
        }),
      );
    },
    [apGroups],
  );
  const { errors, loading } = useSelector(validationSelector);

  const handleBack = useCallback(() => {
    dispatch(resetValidationLab());
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDER_TYPE_PAGE.ID }));
  }, [dispatch]);

  const handleNext = useCallback(() => {
    dispatch(resetValidationLab());
    const selectedIds = checkedApGroups.map((apGroup) => apGroup.id);
    // validation ap groups
    dispatch(
      validateApGroupsRequest({
        apGroupIds: selectedIds,
        callback: () => {
          if (activePageRef.current !== BUILDER_PAGES.BUILDERS_SHARE_SELECT_PAGE.ID) {
            return;
          }
          dispatch(setFormValue({ key: 'apGroupIds', value: selectedIds }));
          dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.BUILDERS_SHARE_INPUT_PAGE.ID }));
        },
      }),
    );
  }, [dispatch, checkedApGroups]);

  useEffect(() => {
    dispatch(getApGroupRequest({ serviceId }));
  }, [dispatch, serviceId]);

  useEffect(() => {
    setApGroups(
      original.map((o, index) => {
        return { ...o, checked: index < LAB_POST_AP_GROUPS_MAX };
      }),
    );
  }, [original, isApGroupsLoading]);

  const content = useMemo(() => {
    if (isApGroupsLoading) {
      return <Spin />;
    }
    if (original.length === 0) {
      return (
        <div className={styles.errorMessageArea}>
          <div className={styles.errorMessage}>{LAB_POST_AP_GROUPS_EMPTY_MESSAGE}</div>
        </div>
      );
    }
    return (
      <div className={styles.gridWrapper}>
        <div className={styles.grid}>
          {apGroups.map((apg) => (
            <BuilderItem
              apGroup={apg}
              key={String(apg.id)}
              serviceId={serviceId}
              disabled={loading}
              onCheck={handleCheck}
            />
          ))}
        </div>
      </div>
    );
  }, [isApGroupsLoading, original, apGroups, serviceId, loading, handleCheck]);

  return (
    <Step
      progress={progress}
      backLabel={BUILDERS_SHARE_PROGRESS_TITLE_STEP0}
      nextLabel="次へ"
      loading={loading || isApGroupsLoading}
      disabled={disabled || isApGroupsLoading}
      onBack={handleBack}
      onNext={handleNext}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{LAB_STEP1_TITLE}</div>
          <div className={styles.annotation}>複数を組み合わせて１つの戦略としてシェアすることもできます</div>
          {Boolean(noDisplayInstrumentIds) && <div>{noDisplayInstrumentIds}は対象外です。</div>}
          <div className={styles.link}>
            <a href={LAB_POST_CONDITION_LINK} target="_blank" rel="noopener noreferrer">
              {LAB_POST_CONDITION}
            </a>
          </div>
        </div>
        {!isApGroupsLoading && (
          <div className={styles.errorMessageArea}>
            {errors && <div className={styles.errorMessage}>{errors}</div>}
            {isCheckMin && original.length > 1 && (
              <div className={styles.errorMessage}>{SHAREABLE_RULE_LOWER_LIMIT_ERROR_MSG}</div>
            )}
            {isCheckMax && (
              <div className={styles.errorMessage}>
                {SHAREABLE_RULE_UPPER_LIMIT_ERROR_MSG_F(LAB_POST_AP_GROUPS_MAX)}
              </div>
            )}
          </div>
        )}
        <div className={styles.contentContainer}>
          <div className={styles.content}>{content}</div>
        </div>
      </div>
    </Step>
  );
});

BuilderSelectPage.propTypes = {
  progress: PropTypes.node.isRequired,
};
